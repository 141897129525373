const buttonText = [
  "Ugh.",
  "🤦🏻‍♂️",
  "☹️",
  "omg dad.",
  "you are the worst",
  "seriously",
  "stop it.",
  "please stop",
  "that was the worst one",
  "ufff",
  "really?"
];

export default buttonText;
